import React from 'react';
import NoiseBackground from './NoiseBackground';
import '../styles/Clients.css'; 

const Clients = () => {
    const clients = [
        'Bill Gates', 'Paris Hilton', 'Fenty Beauty', 'Victoria Beckham Beauty', 
        'Olivia Palermo', 'Isaac Mizerahi', 'Loreal Paris', 'WNBA', 
        'Allure Magazine', 'Vogue Brazil', 'Kelly Cutrone', 'Pat Bo', 
        'Rebecca Minkoff', 'Nile Rogers', 'Victoria Rodriguez'
    ];

    return (
        <div className="clients-container">
            <NoiseBackground />
            <h1>CLIENTELE</h1>
            {clients.map((client, index) => (
                <span key={index} className="client-name">
                    {client}
                </span>
            ))}
        </div>
    );
};

export default Clients;