import React from 'react';
import BookingForm from '../components/BookingForm'; // Adjust the path as necessary
import Navbar from '../components/Navbar';

const BookingPage = () => {
  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white' }}>
      <Navbar />
      <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', marginTop: '40px' }}>
        <h1>Book an Appointment</h1>
        <BookingForm />
      </div>
    </div>
  );
};

export default BookingPage;
