import React, { useEffect } from 'react';
import { Form, Button, Select, DatePicker, Input, message } from 'antd';
import { useLoadScript } from '@react-google-maps/api';
import '../styles/BookingForm.css'; // Adjust the path as necessary
import moment from 'moment'; // Ensure moment is imported

const { Option } = Select;
const libraries = ['places'];

// Example services, you can fetch these from your backend or Firebase
const services = [
    { id: 1, name: 'Cut', duration: 60 },
    { id: 2, name: 'Color', duration: 120 },
    { id: 3, name: 'Style', duration: 90 },
];

// Function to generate time options
const generateTimeOptions = () => {
    const times = [];
    for (let hour = 6; hour <= 21; hour++) { // From 6:00 AM to 9:00 PM
        times.push(`${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour < 12 ? 'AM' : 'PM'}`);
        times.push(`${hour % 12 === 0 ? 12 : hour % 12}:30 ${hour < 12 ? 'AM' : 'PM'}`);
    }
    return times;
};

const BookingForm = () => {
    const [form] = Form.useForm();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    useEffect(() => {
        if (!isLoaded || !document.getElementById('autocomplete')) return;
    
        // Omitting the types option to allow all types of place suggestions
        const options = {
            componentRestrictions: { country: 'us' },
        };
    
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), options);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.formatted_address) {
                // Use formatted_address if available
                form.setFieldsValue({ address: place.formatted_address });
            } else if (place.name) {
                // Use the place name if formatted_address is not available
                form.setFieldsValue({ address: place.name });
            }
        });
    }, [isLoaded, form]);

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        message.success('Booking successful!');
    };

    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };

    const timeOptions = generateTimeOptions();

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" className="booking-form">
            <Form.Item
                label="Enter your address below"
                name="address"
                rules={[{ required: true, message: 'Please enter your address!' }]}
            >
                <Input
                    id="autocomplete"
                    placeholder="Your address"
                    type="text"
                />
            </Form.Item>
            <Form.Item
                name="service"
                label="Select Service"
                rules={[{ required: true, message: 'Please select a service!' }]}
            >
                <Select placeholder="Select a service" allowClear>
                    {services.map(service => (
                        <Option key={service.id} value={service.id}>{service.name}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="date"
                label="Select Date"
                rules={[{ required: true, message: 'Please choose a date!' }]}
            >
                <DatePicker format="dddd, MMMM D" disabledDate={disabledDate} />
            </Form.Item>

            <Form.Item
                name="time"
                label="Select Time"
                rules={[{ required: true, message: 'Please choose a time!' }]}
            >
                <Select placeholder="Select a time" allowClear>
                    {timeOptions.map((time, index) => (
                        <Option key={index} value={time}>{time}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button className="book-appt-btn" htmlType="submit">
                    BOOK
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BookingForm;