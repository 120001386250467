import React from 'react';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import About from './About';
import Clients from './Clients';
import Services from './Services'; // Import the Services component

const HomePage = () => {
    return (
        <>
            <Navbar />
            <HeroSection />
            <About />
            <Services /> {/* Added the Services component */}
            <Clients />
        </>
    );
};

export default HomePage;
