import React from 'react';
import '../styles/Services.css'; // Make sure to create this CSS file

const Services = () => {
  return (
    <section className="services">
      <h2 className="services-title">Hair Services</h2>
      <div className="service-list">
        {[
          { title: 'Bespoke Haircut', description: 'Precision cutting tailored to your unique style and face shape.', price: 'Starting at $150' },
          { title: 'Couture Color', description: 'Custom color techniques including balayage, ombré, and hand-painted highlights.', price: 'Starting at $200' },
          { title: 'Avant-Garde Styling', description: 'Red carpet-worthy blowouts, updos, and editorial styling.', price: 'Starting at $175' },
          { title: 'Luxe Treatments', description: 'Premium conditioning, keratin smoothing, and scalp rejuvenation therapies.', price: 'Starting at $225' },
        ].map((service, index) => (
          <div key={index} className="service-item">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;