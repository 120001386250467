import React from 'react';
import { Card } from 'antd';
import Navbar from '../components/Navbar';
import Services from '../components/Services';

const ServicesPage = () => {
  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <main className="container mx-auto px-4 py-8">
        <Card
          title={<h1 className="text-3xl font-bold text-center text-gray-800">Our Services</h1>}
          className="bg-white shadow-lg"
        >
          <Services />
        </Card>
      </main>
    </div>
  );
};

export default ServicesPage;