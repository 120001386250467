import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import '../styles/Navbar.css';
import logo from '../assets/marsalais.svg';
import { ReactComponent as MenuIcon } from '../assets/menu-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/menu-icon-close.svg';
import { Drawer, Button } from 'antd';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate(); // Use useNavigate hook

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Function to navigate to different paths
    const navigateTo = (path) => {
        setIsMenuOpen(false); // Close the drawer on navigation
        navigate(path); // Use navigate function for navigation
    };

    return (
        <nav className="navbar">
            <div className="menu-icon-container">
                <MenuIcon onClick={toggleMenu} className="menu-icon" />
            </div>
            <div className="navbar-content">
                <img 
                    src={logo} 
                    alt="Logo" 
                    className="navbar-logo" 
                    onClick={() => navigateTo('/')} 
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div className="button-container">
                <button className="book-appointment-btn" onClick={() => navigateTo('/booking')}>BOOK</button>
            </div>

            <Drawer
                className="custom-drawer"
                title={<Button type="text" onClick={toggleMenu} style={{ color: 'white', border: 'none' }}><CloseIcon /></Button>}
                placement="left"
                closable={false}
                onClose={toggleMenu}
                visible={isMenuOpen}
                key="left"
                drawerStyle={{ backgroundColor: '#111', color: 'white' }}
                headerStyle={{ borderBottom: 'none' }}
            >
                <p style={drawerItemStyle}><Button type="link" onClick={() => navigateTo('/')} style={drawerButtonStyle}>HOME</Button></p>
                <p style={drawerItemStyle}><Button type="link" onClick={() => navigateTo('/services')} style={drawerButtonStyle}>SERVICES</Button></p>
                <p style={drawerItemStyle}><Button type="link" onClick={() => navigateTo('/contact')} style={drawerButtonStyle}>CONTACT</Button></p>
            </Drawer>
        </nav>
    );
};

const drawerItemStyle = {
    margin: 0,
    fontWeight: 'bold',
    textTransform: 'uppercase'
};

const drawerButtonStyle = {
    color: 'white',
    padding: 0
};

export default Navbar;
