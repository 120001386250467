import React from 'react';
import NoiseBackground from './NoiseBackground';
import '../styles/About.css'; // Import the new CSS file

const About = () => {
    const dummyData = {
        title: 'The Artistry of Elegance: Unveiling the World of MARSALAIS',
        content: (
            <>
                <p>MARSALAIS stands as a beacon of luxury in the world of hairstyling, epitomizing the fusion of art and elegance. Nestled in the heart of NYC and revered on the global stage, we are the architects of an ethereal realm where each lock of hair undergoes a metamorphosis into artistry, echoing the whispers of sophistication and grace.</p>
                
                <p>Under the esteemed guidance of Brandon McHie, our coterie of adept stylists has not only adorned the cityscapes of New York but has also left indelible marks of their craftsmanship in the illustrious fashion capitals of Paris, Brazil, and Greece. Each stylist is a maestro, weaving magic through their fingers, turning every strand of hair into a symphony of style and elegance.</p>
                
                <p>In the world of MARSALAIS, hair is more than a feature—it’s a canvas brimming with possibilities, awaiting the touch of artistry. Every style curated, every strand caressed, is a testament to a masterpiece meticulously crafted, a narrative intricately woven, echoing the unique allure of the individual.</p>
                
                <p>We transcend the conventional boundaries of hairstyling. At MARSALAIS, we are the alchemists of beauty, engineering not just styles but immersive experiences that transport you to the zenith of personalized elegance and luxury. Every visit is a sojourn, a passage through a sanctuary where artistry and elegance converge, crafting an odyssey of transformation that lingers beyond the mirrors of our salon.</p>
                
                <p>Welcome to MARSALAIS—a realm where every reflection is a testament to a journey of transformation, and every style, a narrative of luxury, meticulously crafted just for you.</p>
            </>
        ),
    };

    return (
        <div className="about-container">
            <NoiseBackground />
            <h1 className="about-header">{dummyData.title}</h1>
            <div className="about-content">
                {dummyData.content}
            </div>
        </div>
    );
};

export default About;
