import React, { useEffect } from 'react';

const NoiseBackground = () => {
  useEffect(() => {
    var canvas = document.getElementById('canvas'),
      ctx = canvas.getContext('2d');

    function resize() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
    resize();
    window.onresize = resize;

    function generate_noise(ctx) {
        console.log('Generating noise'); 
      var w = ctx.canvas.width,
        h = ctx.canvas.height,
        idata = ctx.createImageData(w, h),
        buffer32 = new Uint32Array(idata.data.buffer),
        buffer_len = buffer32.length,
        i = 0;

      for (; i < buffer_len; i++)
        buffer32[i] = ((225 * Math.random() * 0.999 ) | 0) << 24;

      ctx.putImageData(idata, 0, 0);
    }

    var toggle = true;
    (function loop() {
      toggle = !toggle;
      if (toggle) {
        requestAnimationFrame(loop);
        return;
      }
      generate_noise(ctx);
      requestAnimationFrame(loop);
    })();
  }, []);

  return <canvas id="canvas" style={{ position: 'absolute', top: 0, left: 0 }}></canvas>;
};

export default NoiseBackground;
