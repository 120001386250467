import React, { useState, useEffect } from 'react';
import '../styles/HeroSection.css';
import anime from 'animejs/lib/anime.es.js'; // Import anime.js

const HeroSection = () => {
    const [activeBox, setActiveBox] = useState(1);
    const [hoveredBox, setHoveredBox] = useState(null);

    useEffect(() => {
        anime({
            targets: '.content',
            keyframes: [
                {opacity: 0, translateX: -50},
                {opacity: 1, translateX: 0}
            ],
            easing: 'easeOutQuad',
            duration: 500,
        });
    }, [activeBox]);

    const renderContent = (boxNumber) => {
        const content = [
            { title: 'Elegance in Every Strand', desc: 'Discover the art of perfect hair with MARSALAIS.', btn: 'EXPLORE' },
            { title: 'Unleash Your Hair\'s Potential', desc: 'Experience the transformation with MARSALAIS\'s signature touch.', btn: 'DISCOVER MORE' },
            { title: 'Where Style Meets Perfection', desc: 'MARSALAIS crafts the epitome of hair elegance, just for you.', btn: 'SEE THE MAGIC' },
            { title: 'Revolutionize Your Look', desc: 'Step into a world where MARSALAIS turns every strand into a masterpiece.', btn: 'JOIN THE REVOLUTION' },
        ];

        if (window.innerWidth <= 768) {
            return (
                <div className="content">
                    <h1>{content[boxNumber - 1].title}</h1>
                    <p>{content[boxNumber - 1].desc}</p>
                    <button>{content[boxNumber - 1].btn}</button>
                </div>
            );
        }

        if (activeBox === boxNumber) {
            return (
                <div className="content">
                    <h1>{content[boxNumber - 1].title}</h1>
                    <p>{content[boxNumber - 1].desc}</p>
                    <button>{content[boxNumber - 1].btn}</button>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="heroSection">
            {['firstBox', 'secondBox', 'thirdBox', 'fourthBox'].map((boxClass, index) => (
                <div
                    key={index}
                    className={`box ${boxClass} ${activeBox === index + 1 ? 'active' : ''} ${hoveredBox === index + 1 && activeBox !== index + 1 ? 'hover' : ''}`}
                    onClick={() => setActiveBox(index + 1)}
                    onMouseEnter={() => setHoveredBox(index + 1)}
                    onMouseLeave={() => setHoveredBox(null)}
                >
                    {renderContent(index + 1)}
                </div>
            ))}
        </div>
    );
};

export default HeroSection;
